<template>
  <div class="report-section">
    <div class="report-section-title">
      <p class="report-section-title-text">{{ title }}</p>
      <slot class="report-section-title-right" name="title-right"></slot>
      <div class="ahi-bar-right" v-if="title == '氧减统计'">
      <div class="ahi-bar-right-triangle" :style="triangleStyleObject" ></div>
      <div class="ahi-bar-right-top">
        <div :style="greenBarStyleObject"></div>
        <div :style="yellowBarStyleObject"></div>
        <div :style="orangeBarStyleObject"></div>
        <div :style="redBarStyleObject"></div>
      </div>
      <div class="ahi-bar-right-bottom">
        <p class="ahi-bar-right-bottom-number">0</p>
        <p class="ahi-bar-right-bottom-text">正常</p>
        <p class="ahi-bar-right-bottom-number">{{degreeArray[0]}}</p>
        <p class="ahi-bar-right-bottom-text">轻度</p>
        <p class="ahi-bar-right-bottom-number">{{degreeArray[1]}}</p>
        <p class="ahi-bar-right-bottom-text">中度</p>
        <p class="ahi-bar-right-bottom-number">{{degreeArray[2]}}</p>
        <p class="ahi-bar-right-bottom-text">重度</p>
        <p class="ahi-bar-right-bottom-number">∞</p>
      </div>
    </div>
    </div>
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    odiIndex:String,
  },
  data(){
    return{
      degreeStyleArray: [
        { width: 25, color: "#16d585" },
        { width: 25, color: "#EDF200" },
        { width: 25, color: "#F28300" },
        { width: 25, color: "#F2001D" },
      ],
      degreeArray:[5,15,30,100],
     
    }
  },
  computed: {
    triangleStyleObject() {
      if (this.odiIndex === "") {
        return {
          visibility: "hidden",
        };
      } else {
    
        for (let index = 0; index < this.degreeArray.length; index++) {
          const item = this.degreeArray[index];
          var value = Number.parseFloat(this.odiIndex);
          if(value > 100){
            value = 100
          }
          if (value <= item) {
            let width = 0;
            for (let i = 0; i < index; i++) {
              width += this.degreeStyleArray[i].width;
            }
           
            //超过的宽度
            const formerItem = index == 0 ? 0 : this.degreeArray[index - 1];
            const valueDifference = value - formerItem;
            const itemDifference = item - formerItem;
            const widthRate = valueDifference / itemDifference;
       
            width += this.degreeStyleArray[index].width * widthRate;
            
            return {

              marginLeft: `${width - 1.25}%`, //三角形顶点对齐
              borderTopColor: this.degreeStyleArray[index].color,

            };
          }

          continue;
        }
      }

    },
    greenBarStyleObject() {
      return {
        width: `${this.degreeStyleArray[0].width}%`,
        backgroundColor: this.degreeStyleArray[0].color,
      };
    },

    yellowBarStyleObject() {
      return {
        width: `${this.degreeStyleArray[1].width}%`,
        backgroundColor: this.degreeStyleArray[1].color,
      };
    },

    orangeBarStyleObject() {
      return {
        width: `${this.degreeStyleArray[2].width}%`,
        backgroundColor: this.degreeStyleArray[2].color,
      };
    },

    redBarStyleObject() {
      return {
        width: `${this.degreeStyleArray[3].width}%`,
        backgroundColor: this.degreeStyleArray[3].color,
      };
    },
  }
};
</script>

<style lang="scss" scoped>


.report-section {
  width: 100%;
  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &-text {
      color: black;
      font-size: 24px;
      font-weight: 600;
      line-height: 73px;
    }
  }
}

.ahi-bar-right {
    width: rem(400);
    height: rem(32);

    &-triangle {
      display: inline-block;
      width: 0;
      height: 0;
      border-top: rem(10) solid;
      border-left: rem(5) solid transparent;
      border-right: rem(5) solid transparent;
    }

    &-top {
      display: flex;
      width: rem(400);
      height: rem(5);
   

      div {
        height: 100%;
      }
    }

    &-bottom {
      color: #333333;
      font-size: rem(12);
      width: rem(403);
  
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: rem(3);


      &-number {
        color: #999999;
        font-size: rem(12);
      
      }

      &-text {
        color: #333333;
        font-size: rem(12);
        font-weight: bold;
      }
    }
  }
</style>